<template>
    <div style="margin:-12px;width:100vw;">
        <top-nav title="Sesión" />
        <v-skeleton-loader class="mx-auto" type="list-item-two-line, image, list-item-two-line, list-item-two-line, list-item-two-line" v-if="session == null"></v-skeleton-loader>
        <v-card flat style="width:100%;" v-else>
            <v-card-title>{{ session.name }}</v-card-title>
            <v-card-subtitle class="pb-0"><span class="secondary--text">{{ session.type }}</span> | {{ formatDate( session.dateCreated ) }}</v-card-subtitle>

            <qr-code :text="checkInUrl" style="width:100vw;text-align:center;"></qr-code>

            <v-card-text v-show="session.checkIn.length > 0">

                <!-- Check-in list -->
                <v-list two-line>
                    <div class="sticky">
                        <v-divider></v-divider>
                        <v-subheader>Personas que han hecho check-in</v-subheader>
                    </div>
                    <template v-for="checkin in session.checkIn">
                        <v-list-item :key="checkin.id">
                            <v-list-item-content>
                                <v-list-item-title>{{ checkin.customer.firstName }} {{ checkin.customer.lastName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formatDate( checkin.checkinDate ) }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>

            </v-card-text>

            <v-card-text v-show="session.checkIn.length == 0">
                <v-divider></v-divider>

                <div class="text-h5 text-center my-9">
                    <div><v-icon size="96">mdi-map-marker-alert-outline</v-icon></div>
                    <div class="my-5">Nadie ha hecho check-in a esta sesión</div>
                </div>
                
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import TopNav from '@/components/admin/TopNav';
import QrCode from '@/components/QrCode';
import { PetuSession } from '@/classes/session';
import { _st } from '@/softech';
import moment from 'moment';

export default {
    props: {
        sessionId: { type: String, default: '' }
    },
    data() {
        return {
            session: null,
        }
    },
    computed: {
        checkInUrl() {
            if( _st.isNUE( this.session.id ) )
                return '';

            let base = process.env.NODE_ENV === "development" ? "http://localhost:8080/checkin/" : "https://petupower.fitness/checkin/";
            return `${base}${btoa(this.session.id)}`;
        }
    },
    async mounted() {
        // load product
        let session = new PetuSession();

        if( _st.isNUE( this.sessionId ) ) {
            this.session = session.data;
            return;
        }

        await session.load( this.sessionId );
        this.session = session.data;
    },
    methods: {
        formatDate( d ) {
            return moment(d).format('YYYY-MM-DD @ hh:mm a');
        }
    },
    components: { TopNav, QrCode }
}
</script>

<style lang="scss" scoped>
.sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 55px;
    background-color: #fff;
    z-index: 1;
}
</style>